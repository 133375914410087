import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Icon from "../custom-widgets/icon";

import styles from "./personalization-cards.module.scss";

const PersonalizationCards = (props) => {
  const imgData = useStaticQuery(graphql`
    {
      card1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-personal-credit-cards-092324.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2ImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-interest-rewards-checking-092424.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card3ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-smart-start-092424.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  /*
    NOTE: headings (Title) should be limited to 50 characters, and text (description) limited to 95 characters.
    as per design in Figma requirements.
  */
  const defaultCardData = [
    {
      // CARD SLOT 1
      slotNumber: 1,
      cards: [
        {
          cardNumber: 1,
          id: "home-personalization-card-1-default",
          imgData: imgData.card1ImageVar.childImageSharp.gatsbyImageData,
          altText: "A young man using his smartphone to make online card payment.",
          heading: "Does Your Credit Card Give You Cashback?",
          subheading: null,
          text: "Get up to 2% plus perks with WaFd Bank.",
          linkUrl: "/personal-banking/credit-cards"
        }
      ]
    },
    {
      // CARD SLOT 2
      slotNumber: 2,
      cards: [
        {
          cardNumber: 1,
          id: "home-personalization-card-2-default",
          imgData: imgData.card2ImageVar.childImageSharp.gatsbyImageData,
          altText: "Family at the airport, dad with a toddler on his shoulders.",
          heading: "Interest Checking with Money Saving Rewards",
          subheading: null,
          text: "Interest on all balances with gas discounts, telehealth services, and much more.",
          linkUrl: "/personal-banking/checking-account/interest-rewards-checking"
        }
      ]
    },
    {
      // CARD SLOT 3
      slotNumber: 3,
      cards: [
        {
          cardNumber: 1,
          id: "home-personalization-card-3-smart-start",
          imgData: imgData.card3ImageVar.childImageSharp.gatsbyImageData,
          altText: "Parents and daughter playing with ukulele sitting on the floor in the living room at home.",
          heading: "Looking for a House? Get a Smart Start on Home Buying!",
          subheading: null,
          text: "Apply to see if you qualify, and get pre-approved so you know what to look for.",
          linkUrl: "/personal-banking/home-loans/smart-start-down-payment-assistance"
        }
      ]
    }
  ];
  // Use Props cardData if found, else use the default (used here due to imageVariable being used on the cards).
  const cardData = props.cardData || defaultCardData;

  // NOTE: the slotCards and showHideClass logic below is for local build testing only, we can remove this show/hide logic once Tealium is handling that.
  const slotCards = props.defaultCardsArray; // show the defaults for now
  // defaultCardsArray picks the first card from each slot i.e., [1,1,1]
  const firstCard = cardData[0].cards[0];

  /*
   * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
   * Do NOT override the spacing using padding or margin classes on the props.containerClass.
   * If you need to override the section padding because the cards are connected
   * to other sections that need to look like one section, pass the sectionClass
   * "section-padding-top", "section-padding-none", "section-padding-bottom"
   */
  return (
    <>
      {slotCards.length === 1 ? (
        <div
          id={firstCard.id}
          key={firstCard.id}
          tabIndex={0}
          className={`card mb-3 mb-lg-4 overflow-hidden border-radius-12 border position-relative ${styles.personalizationCard}`}
        >
          {firstCard.imgData && (
            <GatsbyImage
              className={`card-image-top border-radius-bottom-0`}
              image={firstCard.imgData}
              alt={firstCard.altText}
            />
          )}
          <div className="card-body">
            {firstCard.heading && (
              <h3 className="text-success font-weight-semi-bold" style={{ textDecoration: "none" }}>
                {firstCard.heading}
              </h3>
            )}
            {firstCard.subheading && <h5 className="text-black font-weight-semi-bold">{firstCard.subheading}</h5>}
            {firstCard.text && (
              <p className={`mb-0 ${styles.cardDescription}`}>
                {firstCard.text}
                <Icon name="arrow-right" class="ml-1 text-primary" />
              </p>
            )}
          </div>
          {firstCard.linkUrl && firstCard.externalLink ? (
            <a
              href={firstCard.linkUrl}
              id={`${firstCard.id}-link`}
              className="stretched-link"
              rel="noopener noreferrer"
              target="_blank"
              aria-label={firstCard.heading}
            >
              <span className="sr-only">{firstCard.heading}</span>
            </a>
          ) : (
            <Link className="stretched-link" to={firstCard.linkUrl} aria-label={firstCard.heading}>
              <span className="sr-only">{firstCard.heading}</span>
            </Link>
          )}
        </div>
      ) : (
        <div id="homepage-personalization-cards" className={`personalization-cards ${props.containerClass}`}>
          <div className="container">
            <ul className="row mb-0 pl-0 list-unstyled row-cols-1 row-cols-md-2 row-cols-lg-3">
              {cardData.map((slot, slotIndex) => {
                return (
                  <li className="col mb-3 mb-lg-4 " key={slot.slotNumber}>
                    <div id={`home-personalization-card-slot-${slot.slotNumber}`} className="h-100 ">
                      {slot.cards.map((card) => {
                        let showHideClass =
                          slotIndex + 1 === slot.slotNumber && card.cardNumber === slotCards[slotIndex]
                            ? "d-block"
                            : "d-none";
                        return (
                          <div
                            id={card.id}
                            key={card.id}
                            tabIndex={0}
                            className={`card h-100 overflow-hidden border-radius-12 position-relative ${showHideClass} ${styles.personalizationCard} `}
                          >
                            {card.imgData && (
                              <GatsbyImage
                                className={`card-image-top border-radius-bottom-0`}
                                image={card.imgData}
                                alt={card.altText}
                              />
                            )}
                            <div className="card-body">
                              {card.heading && (
                                <h3 className="text-success font-weight-semi-bold" style={{ textDecoration: "none" }}>
                                  {card.heading}
                                </h3>
                              )}
                              {card.subheading && (
                                <h5 className="text-black font-weight-semi-bold">{card.subheading}</h5>
                              )}
                              {card.text && (
                                <p className={`mb-0 ${styles.cardDescription}`}>
                                  {card.text}
                                  <Icon name="arrow-right" class="ml-1 text-primary" />
                                </p>
                              )}
                              {card.tertiaryText && <p className="mb-0 mt-3 text-sm">{card.tertiaryText}</p>}
                            </div>
                            {card.linkUrl && card.externalLink ? (
                              <a
                                href={card.linkUrl}
                                id={card.linkId || `${card.id}-link`}
                                className="stretched-link"
                                rel={card.internalPage ? "" : "noopener noreferrer"}
                                target={card.internalPage ? "_self" : "_blank"}
                                aria-label={card.heading}
                              >
                                <span className="sr-only">{card.heading}</span>
                              </a>
                            ) : (
                              <Link
                                className="stretched-link"
                                to={card.linkUrl}
                                aria-label={card.heading}
                                id={card.linkId || `${card.id}-link`}
                              >
                                <span className="sr-only">{card.heading}</span>
                              </Link>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalizationCards;
PersonalizationCards.defaultProps = {
  containerClass: "bg-info",
  defaultCardsArray: [1, 1, 1],
  cardData: null
};
